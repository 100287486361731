import Vue from 'vue';
import VueI18n from 'vue-i18n';

import zh from './zh';
import en from './en';
import { getQS } from '@/lib/script/utils';

Vue.use(VueI18n);
const qs = getQS();
const i18n = new VueI18n({
    locale: /zh/.test(qs.lang) ? 'zh' : 'en',
    messages: {
        zh,
        en,
    },
});

export default i18n;